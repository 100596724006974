import { Injectable, Injector } from '@angular/core';
@Injectable()
export class ServiceLocator {
    private static injector: Injector;

    static getInjector(): Injector {
        return ServiceLocator.injector;
    }

    static setInjector(injector: Injector) {
        ServiceLocator.injector = injector;
    }
}
