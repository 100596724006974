<div class="welcome-container">
    <div class="welcome-block">
        <div class="slide">
            <div class="welcome-bottom-layer">
                <!-- <img src="assets/images/onboarding-curve-grey-layer.svg" alt="" title="" /> -->
                <img *ngIf="!logged" src="assets/images/authentication.png" alt="" title="" />
            </div>
            <div class="security-message" *ngIf="!logged">
                <div class="heading-4 white">
                    <i class="fas fa-lock"></i>
                    <span>{{"Security_Reminder" | translate}}</span>
                </div>
                <div class="text-note">
                    <div>{{"security_msg_1" | translate}}</div>
                    <div>{{"security_msg_2" | translate}}</div>

                    <a routerLink="/auth/contact-us">{{"contact_support" | translate}}</a> {{"security_msg_3" |
                    translate}}
                </div>
            </div>
        </div>
    </div>
</div>