<div class="dashboard-wrapper ">
    <div class="dashboard-bluebg"></div>
    <div class="dashboard-content">
        <!-- <app-navbar></app-navbar> -->
        <div class="loader"><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
            <g id="Group_19727" data-name="Group 19727" transform="translate(-1002 -433)">
              <rect id="Rectangle_16618" data-name="Rectangle 16618" width="80" height="80" transform="translate(1002 433)" fill="none"/>
              <g id="Group_19725" data-name="Group 19725" transform="translate(1002.001 439.25)">
                <g id="Group_18624" data-name="Group 18624" transform="translate(0.999 4.75)">
                  <g id="Group_19726" data-name="Group 19726">
                    <circle id="Ellipse_313" data-name="Ellipse 313" cx="13" cy="13" r="13" transform="translate(52 32)" fill="#ffa72e"/>
                    <rect id="Rectangle_16613" data-name="Rectangle 16613" width="26" height="58" rx="13" fill="#1c008a"/>
                    <rect id="Rectangle_16614" data-name="Rectangle 16614" width="26" height="38" rx="13" transform="translate(26 20)" fill="#d7008a"/>
                  </g>
                </g>
              </g>
            </g>
          </svg>
         <div>Loading...</div>
        </div>
    </div>
</div>
<app-modal [(showModal)]="showNotificationModal" (closeModalTrigger)="doContinue()" [widthModal]="545" [titleModal]="'reminder_access_title'" [showFooter]="false">
  <div class="modal-agr">
    {{'reminder_access_note' | translate}}
    <div class=" agreement-actions">
        <button class="btn-style-2" (click)="doContinue()" >{{"continue" |translate}}</button>  
        <button  class="btn-style-1"  (click)="doLogout()">{{"logout" |translate}}</button>
    </div>
  </div>
</app-modal>
