import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Utils } from '@shared/helper/utils';
import { BehaviorSubject } from 'rxjs'
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { StorageService } from './storage.service';
import { ReminderService } from '@modules/dashboard/sections/reminders/reminder.service';

//import { getMessaging, onMessage, getToken } from "firebase/messaging";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  messages: any = [];
  messaging;
   count=0;
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private ReminderService:ReminderService,
    private _sharedService:SharedService,
    private _storageService:StorageService,
    private http: HttpClient,
    
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    })
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
   
        this.tokenSubscription('global',token);
        this.tokenSubscription('account',token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  tokenSubscription(type:string = 'global',token?) {
    let getLoggedInUser = this._sharedService.getLoggedInUser();
    if( type == 'global' || !Utils.isNullOrUndefinedOrEmpty(getLoggedInUser)) {
      if(!token) {
        token = this._storageService.getStorage('Matensa-FT');
      }
      let FirebaseTokenAccount = this._storageService.getStorage('Matensa-FT-'+type);
      let Selclanguage = this._sharedService.getSelectedLanguage();
      let AccountId = (type == 'global') ? type : getLoggedInUser.AccountId;
      let doSaveNewData = true;
      if(!Utils.isNullOrUndefinedOrEmpty(FirebaseTokenAccount)) {
        let Obj = JSON.parse(FirebaseTokenAccount);
        if(Obj.language == Selclanguage && Obj.token == token && Obj.AccountId == AccountId) {
          if(Obj.topic.substring(0,4) == 'web_') doSaveNewData = false;
          else this.subscribeToTopic(Obj.token,type,false,Obj);

        }
        else {
          this.subscribeToTopic(Obj.token,type,false,Obj);
        }
      }
      if(doSaveNewData) {
        this.subscribeToTopic(token,type);
      }
    }
  }
  afterLoginAction() {
    let firebaseToken = this._storageService.getStorage('Matensa-FT');
    if(!Utils.isNullOrUndefinedOrEmpty(firebaseToken))
      this.tokenSubscription('account',firebaseToken);
    else
      this.requestPermission();
  }
  unSubScribeAllTokens() {

    let accountToken = this._storageService.getStorage('Matensa-FT-account');
    if( !Utils.isNullOrUndefinedOrEmpty(accountToken) ) {
      let Obj = JSON.parse(accountToken); 
      this.subscribeToTopic(Obj.token,'account',false);
    }
    let globalToken = this._storageService.getStorage('Matensa-FT-global');
    if( !Utils.isNullOrUndefinedOrEmpty(globalToken) ) {
      let Obj = JSON.parse(globalToken); 
      this.subscribeToTopic(Obj.token,'global',false);
    }
  }
  subscribeToTopic(token,topicType,st:boolean = true,OldObj?) {
    let Selclanguage = (!Utils.isNullOrUndefinedOrEmpty(OldObj)) ? OldObj.language : this._sharedService.getSelectedLanguage();
    let getLoggedInUser = this._sharedService.getLoggedInUser();
    if(topicType == 'global' || !Utils.isNullOrUndefinedOrEmpty(getLoggedInUser)) {
      let AccountId = (topicType == 'global') ? 'global' : getLoggedInUser.AccountId;
      let topic = 'web_'+AccountId+'_'+Selclanguage;
      if(!Utils.isNullOrUndefinedOrEmpty(OldObj) && !st) topic = OldObj.topic
      let subscribeTopic = null;
      let unSubScribeTopic = null;
      if(st) {
        subscribeTopic = topic
      }
      else {
        unSubScribeTopic = topic;
      }
      let PostData = {
        RegistrationToken:token,
        SubscribeToTopic:subscribeTopic,
        UnsubscribeFromTopic: unSubScribeTopic
      };
      this._sharedService.setWithLoader(false);
      return this.http.post(environment.apiUrl + 'Notification/TopicSubscribeUnsubscribe',PostData).toPromise().then(x => {
     
        if(x['Flag'] == 0) {
          let FirebaseTokenObj = {
            AccountId:AccountId,
            token:token,
            topic:topic,
            language:Selclanguage
          };
          this._storageService.setStorage('Matensa-FT-'+topicType,JSON.stringify(FirebaseTokenObj));
        }
        // debugger
      }).catch(x => {
        // debugger
      }).finally(() => {
        this._sharedService.setWithLoader(true);
      });
    }
  }
  receiveMessage() {
    //this.angularFireMessaging.onMessage().sub
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.count=this.count+1;
        this._sharedService.setNotificationCounter(this.count);
        setTimeout(() => {
          this.ReminderService.Getreminder(payload.data.ReminderId).subscribe(
            (res) => {
        
              let array = [];
             
              array.push(this.ReminderService.dataSource)
              array['0'].unshift(res['Result'])
              array['0'].splice(-1)
              this.ReminderService.dataSource = array['0'];
             
            })
        }, 5000);
       
         
      })
  }
  ///////////////////////////////////////
}
