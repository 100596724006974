<!-- The Modal -->

<!-- <button
  id="btnMyModal"
  class="btn btn-lg btn-outline-primary"
  (click)="open(mymodal)"
>
  Open My Modal
</button> -->

<ng-template #mymodal let-modal class="modal-dialog">
  <!-- <div
    class="modal fade"
    id="myModal"
    [ngClass]="{ show: showModal }"
    role="dialog"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-central"
      [style.width]="widthModal + '%'"
      [style.max-width]="widthModal + '%'"
      role="document"
    >
      <div
        [class]="'modal-content' + classList"
        [style.height]="heightModal + 'px'"
      > -->
  <!-- modal-dialog-centered -->
  <!-- [style.background-color]="'#EBEFF7'" -->
  <!-- Modal Header -->
  <!-- <div class="modal-header" *ngIf="showHeader">
          <h4 class="modal-title">{{ titleModal | translate }}</h4>
          <button
            type="button"
            class="close"
            *ngIf="showCloseIcon"
            (click)="hideModal()"
          >
            &times;
          </button>
        </div> -->

  <!-- Modal body -->
  <!-- <div
          [class]="'modal-body text-start ' + classListBody"
          style="overflow: auto"
        >
          <ng-content></ng-content>
        </div> -->

  <!-- Modal footer -->
  <!-- <div class="modal-footer" *ngIf="showFooter">
          <button
            *ngIf="!onlyOk"
            type="button"
            class="btn btn-danger"
            (click)="hideModal()"
          >
            {{ negativeButtonText | translate }}
          </button>
          <button type="button" class="btn btn-primary" (click)="sendModal()">
            {{ positiveButtonText | translate }}
          </button> -->

  <!-- this button is hidden, used to close from typescript -->
  <!-- <button
            type="button"
            id="close-modal"
            data-dismiss="modal"
            style="display: none"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="modal-header" *ngIf="showHeader">
    <h4 class="modal-title">{{ titleModal | translate }}</h4>
    <button
      type="button"
      class="close"
      *ngIf="showCloseIcon"
      (click)="hideModal()"
    >
      &times;
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer" *ngIf="showFooter">
    <button
      *ngIf="!onlyOk"
      type="button"
      class="btn btn-danger"
      (click)="hideModal()"
    >
      {{ negativeButtonText | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="sendModal()">
      {{ positiveButtonText | translate }}
    </button>

    <button
      type="button"
      id="close-modal"
      data-dismiss="modal"
      style="display: none"
    >
      {{"close" |translate}}
    </button>
  </div>
</ng-template>
