import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-modal",
  templateUrl: "./app-modal.component.html",
  styleUrls: ["./app-modal.component.css"],
})
export class AppModalComponent implements OnInit {
  @Output() closeModalTrigger = new EventEmitter<any>();
  @Output() onOkClick = new EventEmitter<any>();

  @ViewChild("mymodal", { static: true }) mymodal: any;

  @Input()
  showCloseIcon: boolean = true;

  _showModal: boolean = false;
  @Input()
  get showModal() {
    return this._showModal;
  }

  set showModal(valie) {
    this._showModal = valie;
    if (valie) this.open(this.mymodal);
    else this.close();
  }

  //showModal: boolean = false;

  @Input()
  widthModal: string = "100";

  @Input()
  heightModal: string = "";

  @Input()
  titleModal: string = "Title";

  @Input()
  classList: string = "";

  @Input()
  classListBody: string = "";

  @Input()
  showHeader: boolean = true;

  @Input()
  showFooter: boolean = false;

  @Input()
  onlyOk: boolean = false;

  @Input()
  positiveButtonText: string = "yes";

  @Input()
  negativeButtonText: string = "no";

  closeResult: string;

  constructor(private modalService: NgbModal) {}

  open(content) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      windowClass: "popup"+" "+this.classList,
    };
    this.modalService.open(content, ngbModalOptions).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  close() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {}

  sendModal(): void {
    this.onOkClick.emit();
    this.hideModal();
  }

  hideModal(): void {
    this.showModal = false;
    this.closeModalTrigger.emit();
  }
}
