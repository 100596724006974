import { Utils } from "../../shared/helper/utils";
import { Injectable } from "@angular/core";
import * as alertify from "alertifyjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AlertifyService {
  constructor(private translate: TranslateService) {
    alertify.set("notifier", "delay", 8);
    alertify.set("notifier", "position", 'top-right');
  }

  confirm(
    message: string,
    okCallback: () => any,
    cancelCallback: () => any,
    title,
    okBtnText: string = "accept",
    cancelBtnText: string = "decline",
    params: string = null
  ) {
    this.translate
      .get([title, params, message, okBtnText, cancelBtnText])
      .subscribe((x) => {
        alertify
          .confirm(
            x[message] + (params != null ? x[params] : ""),
            okCallback,
            cancelCallback
          )
          .set("closable", false)
          .set("labels", { ok: x[okBtnText], cancel: x[cancelBtnText] })
          .set("title", x[title]);
      });
  }

  confirmWithThreeButtons(
    message: string,
    okCallback: () => any,
    declineCallback: () => any,
    cancelCallback: () => any,
    title: string,
    okBtnText: string = "confirm",
    declineBtnText: string = "decline",
    cancelBtnText: string = "close",
    params: string = null
  ) {
    console.log(message)
    this.translate.get([title, params, message, okBtnText, declineBtnText, cancelBtnText]).subscribe((x) => {
      alertify
        .confirm(
          x[message] + (params != null ? x[params] : ""),
          okCallback,
          declineCallback,
          cancelCallback
        )
        .set("closable", true)
        .set("labels", { ok: x[okBtnText], decline: x[declineBtnText], cancel: x[cancelBtnText] })
        .set("title", x[title]);
    });
  }
   

  
  confirm2(message: string, okCallback: () => any) {
    alertify.confirm(message, (e: any) => {
      if (e) {
        // call function when the user confirms
        okCallback();
      } else {
        // do something else when the user cancels the confirm dialog.
      }
    });
  }

  alertMessage(message: string, title: string, params: string = null) {
    this.translate.get([title, params, message]).subscribe((x) => {
      alertify.alert(
        x[title] + (!Utils.isNullOrUndefinedOrEmpty(params) ? x[params] : ""),
        x[message]
      );
    });
  }

  alertMessageWithParams(
    message: string,
    title: string,
    params: string[] = null
  ) {
    const paramsMessage: string[] = this.prepareParams(params);
    this.translate.get([title, message]).subscribe((x) => {
      alertify.alert(
        x[title],
        x[message] +
          (!Utils.isNullOrUndefinedOrEmpty(paramsMessage) &&
          paramsMessage.length > 0
            ? "( " + paramsMessage.join(", ") + " )"
            : "")
      );
    });
  }

  prepareParams(params: string[]) {
    let paramsMessage: string[] = [];
    if (params != null && params.length > 0) {
      params.forEach((x) => {
        paramsMessage.push(this.translate.instant(x));
      });
    }

    return paramsMessage;
  }

  success(message: any) {
    let Msg = message;
    if(message.Message) Msg = message.Message;
    this.translate.get([Msg]).subscribe((x) => {
      let MsgContent = '<div class="alertify-custom-msg"><span class="msg-icon"><i class="fas fa-check"></i></span><span class="msg"><b>'+ this.translate.instant("success")+'</b>'+x[Msg]+'</span><a class="msg-close"><i class="fas fa-times"></i></a></div>';
      alertify.success(MsgContent);
    });
  }

  error(message: any) {
    let Msg = message;
    if(message.Message) Msg = message.Message;
    this.translate.get([Msg]).subscribe((x) => {
      let MsgContent = '<div class="alertify-custom-msg"><span class="msg-icon"><i class="fas fa-ban"></i></span><span class="msg"><b>'+ this.translate.instant('error')+'</b>'+x[Msg]+'</span><a class="msg-close"><i class="fas fa-times"></i></a></div>';
      alertify.error(MsgContent);
    });
  }

  warning(message: any) {
    let Msg = message;
    if(message.Message) Msg = message.Message;
    this.translate.get([Msg]).subscribe((x) => {
      let MsgContent = '<div class="alertify-custom-msg"><span class="msg-icon"><i class="fas fa-exclamation"></i></span><span class="msg"><b>'+ this.translate.instant('warning')+'</b>'+x[Msg]+'</span><a class="msg-close"><i class="fas fa-times"></i></a></div>';
      alertify.warning(MsgContent);
    });
  }

  message(message: any) {
    let Msg = message;
    if(message.Message) Msg = message.Message;
    let MsgContent = '<div class="alertify-custom-msg"><span class="msg-icon"><i class="fas fa-info"></i></span><span class="msg"><b>'+ this.translate.instant('info')+'</b>'+Msg+'</span><a class="msg-close"><i class="fas fa-times"></i></a></div>';
    alertify.message(MsgContent);
  }
}
