// // src/app/auth/guards/view-or-edit.guard.ts
// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { AuthService } from '../auth.service';
// import { map, Observable, take } from 'rxjs';
// // import { AuthService } from '../services/auth.service';

// @Injectable({
//   providedIn: 'root'
// })
// export class VieweditGuard implements CanActivate {
//   constructor(private authService: AuthService, private router: Router) {}

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//     const page = next.data['page'] as string;
//     return this.authService.login().pipe(
//       take(1),
//       map(() => {
//         console.log('Guard checking permissions for page:', page);
//         if (this.authService.canEdit(page) || this.authService.canView(page)) {
//           return true;
//         } else {
//           this.router.navigate(['/unauthorized']);
//           return false;
//         }
//       })
//     );
//   }
// }

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth.service";
import { PermissionsService } from "../services/permissions.service";

@Injectable({
  providedIn: "root",
})
export class VieweditGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private permissionsService: PermissionsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // alert('j');
    const page = route.data.page as string;
    const action = route.data["action"];
    console.log('eeeee',action,page)
    // if (action === "Edit" && !this.permissionsService.canEdit(page)) {
      // this.router.navigate(["/unauthorized"]);
    //   return false;
    // }

    // if (action === "View" &&!this.permissionsService.canView(page)) {
      // this.router.navigate(["/unauthorized"]);
    //   return false;
    // }

    return true;
  }
  
}
