import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: any;
  constructor(private http: HttpClient) { }

  async loadConfig() {
    //  await this.http
    //   .get<any>('assets/config.json')
    //   .toPromise()
    //   .then(config => {
    //     window.config = config;
    //     this.config = config;
    //   });
  }

  public init() {
    // return from(
    //   fetch('./assets/config.json').then(function (response) {
    //     return response.json();
    //   })
    // ).pipe(
    //   map((config) => {
    //     window.config = config;
    //     return config;
    //   })).toPromise();
  }
}
