import { Guid } from "./../models/Guid";
import { environment } from "./../../../environments/environment";
import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { SharedService } from "@core/services/shared.service";
import { StorageService } from "@core/services/storage.service";
import { Utils } from "@shared/helper/utils";
@Injectable({
  providedIn: "root",
})
export class JWTLocalInterceptor implements HttpInterceptor {
  private requests: string[] = [];
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private storageService: StorageService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.sharedService.setRequests(request);
    //this.requests.push(request.url);
    if (request.method === 'POST' || request.method === 'PUT') {
      //this.shiftDates(request.body);
    }
    this.sharedService.isLoading.next(true);
    let languageCode = this.sharedService.getSelectedLanguage();
    if (
      !Utils.isNullOrUndefinedOrEmpty(languageCode) &&
      languageCode.toLowerCase().indexOf("ar") != -1
    ) {
      languageCode = "ar-lb";
    }
    //debugger;

    const closeRequest: HttpRequest<any> = request.clone({
      //url: request.url + "?culture=" + languageCode,
      url: request.url,
      withCredentials:true,
      setHeaders: {
        'Accept-Language': this.sharedService.getSelectedLanguage(),
        Authorization: `Bearer ${this.storageService.getStorage("token")}`,
        //Authorization: `${this.storageService.getStorage("token")}`,
        AccountId: this.sharedService.getCurrentAccount() || Guid.empty 
      },
    });
    return next.handle(closeRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(request);
            // do stuff with response if you want 
          }
        },
        (err: any) => {
          this.removeRequest(request);
        },
        () => {
          if (
            !Utils.isNullOrUndefinedOrEmpty(
              this.storageService.getSessionStorage("valid_token")
            )
          ) {
            const currentTimePlusHalfHour = new Date(
              new Date().setMinutes(
                new Date().getMinutes() + parseInt(environment.sessionLife)
              )
            ).getTime();
            this.storageService.setSessionStorage(
              "valid_token",
              currentTimePlusHalfHour.toString()
            );
          }
        }
      )
    );
  }

  shiftDates(body) {
    if (body === null || body === undefined) {
        return body;
    }
    if (typeof body !== 'object') {
        return body;
    }
    for (const key of Object.keys(body)) {
        const value = body[key];
        if (value instanceof Date) {
            //body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), value.getSeconds()));
            body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()));
        } 
        else if (typeof value === 'object') {
            //this.shiftDates(value);
        }
    }
  }
  removeRequest(req: HttpRequest<any>) {
    this.sharedService.removeRequest(req);
  }
}
