import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "@shared/components/base/base.component";
import { AuthService } from '@modules/auth/auth.service';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent extends BaseComponent implements OnInit {

  constructor( public authService: AuthService) {
    super();
  }

  ngOnInit(): void {
  }
  public  get logged(){
    return this.authService.isAuthenticated();
  }
}
