// src/app/auth/services/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from './permissions.service';
import { UserRoles } from '../roles.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private userRoles: UserRoles;

  // constructor(private router: Router, private permissionsService: PermissionsService) { }

  // login(): void {
  //   // Simulate login logic here
  //   // Typically, you would authenticate with your backend and then fetch permissions
  //   // this.permissionsService.fetchPermissions().subscribe(response => {
  //   //   this.permissionsService.setUserRoles(response.Roles);
  //   //   this.userRoles = response.Roles;
  //   //   console.log('User roles after login:', this.userRoles);
  //   //   this.router.navigate(['/dashboard']); // Navigate to default route after login
  //   // });
  // }

  // logout(): void {
  //   // Implement logout logic here if needed
  //   this.userRoles = null;
  //   this.router.navigate(['/login']); // Navigate to login page after logout
  // }

  // isLoggedIn(): boolean {
  //   // Implement check for user login status if needed
  //   return !!this.userRoles;
  // }

  // canEdit(page: string): boolean {
  //   return this.userRoles && this.userRoles[page]?.Edit;
  // }

  // canView(page: string): boolean {
  //   return this.userRoles && this.userRoles[page]?.View;
  // }
}
