import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { Utils } from '@shared/helper/utils';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthService
) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route) {
    if( !this.authService.isAuthenticated()) {
      return true
    }
    else {
      this.authService.logout_noauth();
      return false;
    }
  }
}
