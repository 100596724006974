// // // src/app/auth/services/permissions.service.ts
// // import { Injectable } from '@angular/core';
// // import { HttpClient } from '@angular/common/http';
// // import { Observable, of } from 'rxjs';
// // import { UserRoles } from '../roles.model';

// // @Injectable({
// //   providedIn: 'root'
// // })
// // export class PermissionsService {
// //   private apiUrl = 'YOUR_API_ENDPOINT'; // Replace with your API endpoint
// //   private userRoles: UserRoles;

// //   constructor(private http: HttpClient) { }

// //   fetchPermissions(): Observable<{ Roles: UserRoles }> {
// //     // return this.http.get<{ Roles: UserRoles }>(this.apiUrl);
// //     const roles: UserRoles = {
// //       pages: { Edit: true, View: true },
// //       dashboard: { Edit: true, View: true },
// //       AccessControl: { Edit: true, View: true },
// //       UserManagement: { Edit: true, View: true },
// //       MConnect: { Edit: true, View: true },
// //       ExternalSource: { Edit: true, View: true },
// //       SystemSettings: { Edit: true, View: true },
// //       WhishMoney: { Edit: true, View: true },
// //       SystemHealth: { Edit: true, View: true },
// //       Logs: null
// //     };
// //     console.log('Fetched roles:', roles);
// //     // Simulate an API response using RxJS 'of' function
// //     return of({ Roles: roles });
// //   }

 
// //   setUserRoles(roles: UserRoles) {
// //     this.userRoles = roles;
// //     console.log('User roles set:', this.userRoles);
// //   }

// //   getUserRoles(): UserRoles {
// //     return this.userRoles;
// //   }

// //   canEdit(page: string): boolean {
// //     console.log('Checking edit permission for:', page, this.userRoles[page]?.Edit ?? false);
// //     return this.userRoles[page]?.Edit ?? false;
// //   }
// //   canView(page: string): boolean {
// //     console.log('Checking view permission for:', page, this.userRoles[page]?.View ?? false);
// //     return this.userRoles[page]?.View ?? false;
// //   }
// // }


// import { Injectable } from "@angular/core";
// import { HttpClient } from "@angular/common/http";
// import { Observable } from "rxjs";
// import { environment } from "src/environments/environment";
// import { map } from "rxjs/operators";
// import { ConnectionprofileComponent } from "@modules/dashboard/sections/connection-profile/connectionprofile/connectionprofile.component";
// import { SharedService } from "@core/services/shared.service";

// export interface UserRoles {
//   [key: string]: { Edit: boolean; View: boolean };
// }

// @Injectable({
//   providedIn: "root",
// })
// export class PermissionsService {
//   private userRoles: UserRoles = {};

//   constructor(private http: HttpClient,private sharedService: SharedService) {}

//   fetchPermissions(): Observable<UserRoles> {
//     // Simulate API call with static data for now
//     // ConnectionprofileComponent.SwitchData;
//     console.log('dddd',this.sharedService.getUserInfo());
//     const roles = {
//       Roles: {
//         pages: { Edit: false, View: true },
//         Dashboard: { Edit: true, View: true },
//         AccessControl: { Edit: true, View: true },
//         UserManagement: { Edit: true, View: true },
//         MConnect: { Edit: true, View: true },
//         ExternalSource: { Edit: true, View: true },
//         SystemSettings: { Edit: true, View: true },
//         WhishMoney: { Edit: true, View: true },
//         SystemHealth: { Edit: true, View: true },
//         Logs: { Edit: false, View: true },
//       },
//     };
//     return new Observable((observer) => {
//       observer.next(roles.Roles);
//       observer.complete();
//     });
//   }

//   setUserRoles(roles: UserRoles): void {
//     console.log('e',roles)
//     this.userRoles = roles;
//   }

//   getUserRoles(): UserRoles {
//     return this.userRoles;
//   }




//   canView(page: string): boolean {
//     const permissions = this.userRoles[page];
//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.View;
//   }

//   canEdit(page: string): boolean {
//     const permissions = this.userRoles[page];
//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.Edit;
//   }
// }


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '@core/services/shared.service';

export interface RolePermission {
  RoleLkpId: number;
  AdminClaimsTypeLkpId: number; // Page ID
  AdminClaimsPermissionLkpId: number; // Permission ID (View, Edit, etc.)
}

export interface Permission {
  Edit: boolean;
  View: boolean;
  Add: boolean;
}
export interface UserRoles {
  [page: string]: Permission | null;
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private userRoles: Permission[] = [];

  // Simulate fetching permissions from local storage or API
  constructor() {
    const rolesFromStorage = localStorage.getItem('userdata');
    console.log(rolesFromStorage)
    if (rolesFromStorage) {
      this.userRoles = JSON.parse(rolesFromStorage);
      this.setUserRoles(this.userRoles)
    }
  }

  // Save roles to local storage for future use
  // setUserRoles(roles: RolePermission[]) {
  //   this.userRoles = roles;
  //   localStorage.setItem('userRoles', JSON.stringify(roles));
  // }
  setUserRoles(roles: any) {
    this.userRoles = roles.reduce((acc: UserRoles, role: any) => {
      const page = role.AdminClaimsTypeLkpId.toString(); // Assuming pageId is a string
      const action = role.AdminClaimsPermissionLkpId;
      
      if (!acc[page]) {
        acc[page] = { Edit: false, View: false, Add: false }; // Initialize permissions
      }
      
      if (action === 160) { // Edit
        acc[page].Edit = true;
      } else if (action === 159) { // Add
        acc[page].Add = true;
      } else if (action === 158) { // View
        acc[page].View = true;
      }
      
      return acc;
    }, {});
    localStorage.setItem('userRoles', JSON.stringify(this.userRoles)); // Save roles to localStorage
  }
  // Fetch roles from local storage or any storage
  fetchPermissions(): Observable<Permission[]> {
    return of(this.userRoles);
  }

  // Check if user has a specific permission for a page
  hasPermission(pageId: number, actionId: number): boolean {
    console.log('User Roles:', this.userRoles); // Log user roles for debugging
    console.log('User Roles:', pageId,actionId); // Log user roles for debugging
    const permissions = this.userRoles[pageId];

    if (!permissions) {
      return false; // No permissions found for the given page
    }

    // Check permissions based on action ID and hierarchy
    if (actionId === 160) { // Edit permission ID
      return permissions.Edit; // User can edit if they have edit permission
    } else if (actionId === 159) { // Add permission ID
      return permissions.Add || permissions.Edit; // User can add if they have add or edit permission
    } else if (actionId === 158) { // View permission ID
      return permissions.View || permissions.Add || permissions.Edit; // User can view if they have view, add, or edit permission
    } else {
      return false; // Action not recognized
    }
  }
}



// export interface PagePermissions {
//   Add: boolean;
//   Edit: boolean;
//   View: boolean;
//   Delete: boolean;
//   Display: boolean;
// }

// export interface UserRoles {
//   [key: string]: PagePermissions;
// }

// @Injectable({
//   providedIn: 'root'
// })
// export class PermissionsService {
//   private userRoles: UserRoles = {};
//   private rolesSubject = new BehaviorSubject<any>(null);

//   constructor(private http: HttpClient,private sharedService: SharedService) {
//     this.loadRolesFromStorage();
//   }

//   private loadRolesFromStorage() {
//     const storedRoles = localStorage.getItem('userRoles');
//     if (storedRoles) {
//       try {
//         this.userRoles = JSON.parse(storedRoles);
//       } catch (e) {
//         console.error('Error parsing user roles from localStorage:', e);
//         this.userRoles = {};
//       }
//     }
//   }
//   fetchPermissions(): Observable<UserRoles> {
//     const roles = JSON.parse(localStorage.getItem('userdata'))
//     console.log(roles)
//     if (roles) {
//       try {
//         const userData = roles;
//         this.userRoles=roles;
//         if (userData) {
//           const transformedRoles: UserRoles = {};
//           for (const [key, value] of Object.entries(userData)) {
//               transformedRoles[key] = value as PagePermissions;
//             }
//           return of(transformedRoles);
//         }
//       } catch (e) {
//         console.error('Error parsing user data from localStorage:', e);
//       }
//     }
//     return of({});
//   }
//   fetchAgentPermissions(): Observable<UserRoles> {
//     alert('e')
//     const roles = JSON.parse(localStorage.getItem('userdata'));
//     if (roles) {
//       try {
//         const userData = JSON.parse(roles);
//         if (userData && userData.Claims) {
//           const transformedRoles: UserRoles = {};
//           for (const [key, value] of Object.entries(userData.Claims)) {
//             transformedRoles[key] = value as UserRoles[keyof UserRoles];
//           }
//           return of(transformedRoles);
//         }
//       } catch (e) {
//         console.error('Error parsing user data from localStorage:', e);
//       }
//     }
//     return of({});
//   }
//   setUserRoles(roles) {
//     this.userRoles = roles;
//     localStorage.setItem('userRoles', JSON.stringify(roles));
//   }

//   getUserRoles(): Observable<any> {
//     return this.rolesSubject.asObservable();
//   }

//   canView(page: string): boolean {
//     const permissions = this.userRoles[page];
//     console.log('1',permissions)
//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.View;
//   }

//   canEdit(page: string): boolean {
//     const permissions = this.userRoles[page];
//     console.log('2',permissions)

//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.Edit;
//   }
//   canDelete(page: string): boolean {
//     const permissions = this.userRoles[page];
//     console.log('2',permissions)

//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.Delete;
//   }
//   canAdd(page: string): boolean {
//     const permissions = this.userRoles[page];
//     console.log('2',permissions)

//     if (!permissions) {
//       console.warn(`No permissions found for page: ${page}`);
//       return false;
//     }
//     return permissions.Add;
//   }

//   permissionsLoaded(): boolean {
//     return Object.keys(this.userRoles).length > 0;
//   }
// }
