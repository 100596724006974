import { Utils } from "../../shared/helper/utils";
import { SharedService } from "./shared.service";
import { Injectable } from "@angular/core";
import { LookupEnums } from "@shared/enumeration/LookupEnum";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class StorageService {
  jwtHelper = new JwtHelperService();
  constructor(
    private sharedService: SharedService,
    public router: Router
    ) {}

  setLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  setStorage(key, value) {
    if (this.sharedService.isRememderMe) {
      localStorage.setItem(key, value);
    } else {
      sessionStorage.setItem(key, value);
    }
  }

  getToken(key) {
    return this.getStorage(key);
  }

  clearLogin(withRedirect:string = '') {
    console.warn(localStorage)
    let userstorage=localStorage.getItem("localStorageInfo")
    // this.storageService.removeStorage("logged_in_user");
    this.removeStorage("Matensa-FT-global");
    this.removeStorage("Matensa-FT-account");
    this.removeStorage("AccountId");
    this.removeSessionStorage("valid_token");
    this.removeStorage('IsPersonal');
    this.removeStorage("token");
    this.removeStorage("refreshToken");
    //this.storageService.removeStorage("user");
    this.removeStorage("matensaID");

    this.clearStorage();
    if (!Utils.isNullOrUndefinedOrEmpty(userstorage)) {
      this.setLocalStorage('localStorageInfo',userstorage);
    }
  
    if(withRedirect == '') {
      this.router.navigate(['/auth/login']);
    }
    if(withRedirect != '') {
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: withRedirect }});
    }
    console.warn(localStorage)
  }
  

  getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  getStorage(key): any {
    if (this.sharedService.isRememderMe) return localStorage.getItem(key);
    return sessionStorage.getItem(key);
  } 
  getJsonStorage(key): any {
    if (this.sharedService.isRememderMe)
      return JSON.parse(localStorage.getItem(key));

    return JSON.parse(sessionStorage.getItem(key));
  }

  getCurrentUserName(key): string {
    let user: any = "";
    if (this.sharedService.isRememderMe)
      user = JSON.parse(localStorage.getItem(key));
    else user = JSON.parse(sessionStorage.getItem(key));
    if (!Utils.isNullOrUndefinedOrEmpty(user)) {
      if (user.UserTypeLKPId == LookupEnums.UserTypeEnum.Personal)
        return user.FirstName;
      return user.CompanyName;
    }
    return "";
  }

  removeSessionStorage(key) {
    sessionStorage.removeItem(key);
  }

  removeLocalStorage(key) {
    localStorage.removeItem(key);
  }

  removeStorage(key) {
    if (this.sharedService.isRememderMe) {
      localStorage.removeItem(key);
    } else {
      sessionStorage.removeItem(key);
    }
  }

  clearStorage() {
   // if (this.sharedService.isRememderMe) {
    let selLang = this.getLocalStorage('selected_language');
      localStorage.clear();
      this.setLocalStorage('selected_language',selLang);
      this.setLocalStorage('lang',selLang);
    //} else {
      sessionStorage.clear();
    //}
  }

  //////////////
  decodedToken:any;
  setDecodedToken() {
    let Token = this.getStorage("token");
    if(!Utils.isNullOrUndefinedOrEmpty(Token))
      this.decodedToken = this.jwtHelper.decodeToken(Token);
  }
  getDecodedToken() {
    if(Utils.isNullOrUndefinedOrEmpty(this.decodedToken)) {
      let Token = this.getStorage("token");
      return this.decodedToken = this.jwtHelper.decodeToken(Token);
    }
    else {
      return this.decodedToken;
    } 
  }
  public saveRefreshToken(token: string): void {
    if (this.sharedService.isRememderMe) {
      localStorage.removeItem('refreshToken');
      localStorage.setItem('refreshToken',token)
    } else {
      sessionStorage.removeItem('refreshToken');
      sessionStorage.setItem('refreshToken',token)
    }
  }
  public getRefreshToken(): string | null {
    if (this.sharedService.isRememderMe) {
      return localStorage.getItem('refreshToken');
    } else {
      return sessionStorage.getItem('refreshToken');
    }
  }
  getLoggedInUser() {
    return null;
  }
}
