// const envv = {
//   production: false,
//   // apiUrl: 'api/',
//   // originUrl: 'http://localhost:5000',
//   // apiUrl: 'http://localhost:5000/api/',
//   originUrl: 'https://api-test.matensa.com/',
//   apiUrl: 'https://api-test.matensa.com/api/',
//   sessionLife: '30',
//   firebaseConfig: {
//     apiKey: "AIzaSyCq0a8DGJXUW9sJODoMUnzUy_RZAJ7KSv4",
//     authDomain: "matensa-3d588.firebaseapp.com",
//     projectId: "matensa-3d588",
//     storageBucket: "matensa-3d588.appspot.com",
//     messagingSenderId: "837388037560",
//     appId: "1:837388037560:web:197da3db3e77d7ca2ef34d",
//     measurementId: "G-9D3QX4DPNG",
//     vapidKey:"BLmdynAZvexIz7nPeON1Ezv_dfxW--ln5TIhNAGCDLS0PvyejpEmJFX2vVhYS9ISgQdQ2phGenExAZ8_ehVE9Sw"
//   }
// };
import { default as env1 } from "../assets/config.json";
let env = env1;
//let env = window["env"];
//////////////////////////////////////
env.production = false;
export const environment = env;