import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieIsDisabledComponent } from './cookie-is-disabled/cookie-is-disabled.component';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [
    CookieIsDisabledComponent
  ],
  imports: [
    CommonModule,
    BrowserModule
  ],
  bootstrap: [CookieIsDisabledComponent],
})
export class CookieDisabledModule { }
