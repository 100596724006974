import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner'
import { MessagingService } from "@core/services/messaging.service";
import { ProfileService } from '@modules/admin/my-account/profile.service';
import { ProfileSettingsService } from '@modules/dashboard/sections/profile-settings/profile-settings.service';
import { SharedService } from '@core/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@modules/auth/auth.service';
import { StorageService } from '@core/services/storage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Matensa';
  message;
  _spinner: boolean = true;
  _withLoader: boolean = true;
  get spinner() {
    return this._spinner
  }
  get withLoader() {
    return this._withLoader
  }
  public innerWidth: any;

  constructor(
    private spinnerngx: NgxSpinnerService,
    private messagingService: MessagingService,
    private _ProfileService: ProfileService,
    private profileSettings: ProfileSettingsService,
    private sharedService: SharedService,
    private _StorageService: StorageService,
    private _AuthService: AuthService
  ) {
    if (this._AuthService.isAuthenticated()) {
      this._ProfileService._setAppLaunchData();
      this.profileSettings._setRolesPermissions();
    }
    else {
      this._ProfileService._logoutProfileData();
      this._StorageService.clearLogin();
    }
  }
  ngOnInit() {
    /////////////
    /////////////
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.innerWidth = window.innerWidth;
    this.sharedService.isLoading.subscribe(state => {
      this._spinner = state
    });
    this.sharedService.withLoader.subscribe(state => {
      this._withLoader = state
    });
    this.spinnerngx.show();
    this.sharedService._selectedLanguage.subscribe(x => {
      if (x == 'ar') {
        document.body.classList.add('rtl');
        document.querySelector("body").classList.add("rtl");
      }
      else document.body.classList.remove('rtl');
    })
    // if (this.selectedLanguage == 'ar') {
    //   document.body.classList.add('rtl');
    //   document.querySelector("body").classList.add("rtl");
    // }
    // else document.body.classList.remove('rtl');
  }
  ngAfterViewInit() {
  }
  ////////////////////
  ////////////////////////////////////
}
