// import { Utils } from '../../shared/helper/utils';
// import { Injectable } from '@angular/core';
// import { AuthService } from './auth.service';
// import { CanActivate, ActivatedRouteSnapshot, ActivatedRoute, CanLoad, Route, RouterStateSnapshot, Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { StorageService } from '@core/services/storage.service';

// @Injectable({
//     providedIn: 'root'
// })
// export class AuthGuard implements CanActivate, CanLoad {
//     constructor(
//         private authService: AuthService
//     ) { }
//     canActivate(
//         next: ActivatedRouteSnapshot,
//         state: RouterStateSnapshot
//     ): boolean | Observable<boolean> | Promise<boolean> {
//         return new Promise((resolve, reject) => {
//             if (this.authService.isAuthenticated()) {
//                 resolve(true);
//             }
//             else {
//                 this.authService.logout_noauth(state.url);
//                 resolve(false);
//             }
//         });
//     }
//     canLoad(route: Route) {
//         let returnUrl = (route.path == 'wizard') ? '/wizard' : '/dashboard/' + route.path;
//         if (this.authService.isAuthenticated()) {
//             return true;
//         }
//         else {
//             this.authService.logout_noauth(returnUrl);
//             return false
//         }
//     }
// }



import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from './services/permissions.service';
import { AlertifyService } from '@core/services/alertify.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
    constructor(
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private router: Router,
        private alertify:AlertifyService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
      ): Promise<boolean> {
        return new Promise((resolve, reject) => {
          const page = next.data.page;
          const action = next.data.action;
          console.log('13',page,action)
          if (this.authService.isAuthenticated()) {
            if (page && action) {
              // if ((action === 160 && !this.permissionsService.canEdit(page)) ||
              //     (action === 158 && !this.permissionsService.canView(page)) ||
              //     (action === 159 && !this.permissionsService.canAdd(page))) {
              //   console.log('Insufficient permissions, showing alert...');
              //   this.alertify.alertMessage('You are unauthorized to open this section', 'Unauthorized', null);
              //   resolve(false);
              // } else {
              //   resolve(true);
              // }
              if (this.permissionsService.hasPermission(page, action)) {
                resolve(true);
              } else {
                this.alertify.alertMessage('You are not authorized to access this page.', 'Unauthorized', null);
                // this.router.navigate(['/unauthorized']);
                resolve(false);
              }
            } else {
              resolve(true);
            }
          } else {
            console.log('Not authenticated, redirecting to login...');
            this.authService.logout_noauth(state.url);
            resolve(false);
          }
        });
    }

    canLoad(route: Route): boolean {
        let returnUrl = (route.path === 'wizard') ? '/wizard' : '/dashboard/' + route.path;
        if (this.authService.isAuthenticated()) {
            return true;
        } else {
            this.authService.logout_noauth(returnUrl);
            return false;
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        return this.canActivate(childRoute, state);
    }
}
