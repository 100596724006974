import { FormGroup, ValidationErrors } from "@angular/forms";
import { BaseComponent } from "@shared/components/base/base.component";
import { TranslateService } from "@ngx-translate/core";

const PNF = require('google-libphonenumber').PhoneNumberFormat;
const PNV = require('google-libphonenumber').PhoneNumberUtil.ValidationResult;
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export class Utils {
  translate: TranslateService;
  static isNullOrUndefinedOrEmpty(term: any): boolean {
    return (
      typeof term == "undefined" ||
      typeof term == undefined ||
      term == "undefined" ||
      term == undefined ||
      term == null ||
      term == "null" ||
      term == "NaN" ||
      term == '00000000-0000-0000-0000-000000000000' ||
      term.length == 0
    );
  }

  static isSocialMediaURL(selected) {
    let Link = selected.Text;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    let array = Link.split(' ');
    let array2 = Link.split('/')

    if (pattern.test(selected.Text) == false || (array.length == 1 && array2.length == 1)) {
      // if(pattern2.test(selected.Text)==false){
      if (selected.SocialTypeLKPId == 129) {
        Link = 'https://www.linkedin.com/in/' + selected.Text
      } else if (selected.SocialTypeLKPId == 131) {
        Link = 'https://www.instagram.com/' + selected.Text
      } else if (selected.SocialTypeLKPId == 130) {
        Link = 'https://www.facebook.com/' + selected.Text
      } else if (selected.SocialTypeLKPId == 132) {
        Link = 'https://www.twitter.com/' + selected.Text
      }
      // }
    }
    //console.warn(Link)

    return Link;
  }
  static isNullOrUndefined(term: any): boolean {
    return typeof term == "undefined" || term == null || term == "null";
  }

  static isUndefined(term: any): boolean {
    return typeof term == "undefined";
  }

  static mappingModel(modelToMap: any, object): any {
    if (!Utils.isNullOrUndefinedOrEmpty(object))
      Object.keys(object).forEach((field) => {
        if (!Utils.isUndefined(modelToMap[field])) {
          modelToMap[field] = object[field];
        }
      });

    return modelToMap;
  }

  static isEqual = (a, b) => {
    if (a == "$id" || b == "$id") return true;
    if (a === b) return true;
    if (a instanceof Date || b instanceof Date) {
      console.log("date");
      return new Date(a).getTime() === new Date(b).getTime();
    }
    if (!a || !b || (typeof a !== "object" && typeof b !== "object"))
      return a === b;
    if (a === null || a === undefined || b === null || b === undefined)
      return false;
    if (a.prototype !== b.prototype) {
      return false;
    }
    let keys = Object.keys(a).filter((x: any) => x != "$id");
    let bKeys = Object.keys(b).filter((x: any) => x != "$id");
    if (keys.length !== bKeys.length) {
      return false;
    }
    return keys.every((k) => Utils.isEqual(a[k], b[k]));
  };

  static IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  static getFormValidationErrors(fg) {
    Object.keys(fg.controls).forEach(key => {
      const controlErrors: ValidationErrors = fg.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.warn('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  static validatePhoneNumber(input_str) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(input_str);
  }
  static setMessage(MsgKey) {
    let msg = MsgKey;
    switch (MsgKey) {

      case 'NO_PAYMENT_METHODS_EXIST':
        msg = 'Payments methods does not exists, please make sure to setup your accepted payments methods in your profile before creating any agreement.';
        break;
      case 'FAILURE_ITEM_UPDATED':
        msg = 'Failed item updated, if anything wrong we will send you another notification.';
        break;
      case 'FILE_UPLOADED':
        msg = 'File is uploaded successfully, our system will send you a notification of failure records once import done.';
        break;
      case 'NO_ENOUGH_BALANCE':
        msg = 'No enough balance';
        break;
      case 'ATTACHMENT_REQUIRED':
        msg = 'Attachment is required';
        break;
      case 'AGREEMENT_IS_ALREADY_ACTIVATED_UPLOAD_DOCUMENTS':
        msg = 'You cannot proceed with this action unless agreement is still pending or under review.';
        break;
      case 'EMPTY_INPUTS':
        msg = 'Empty Inputs';
        break;
      case 'BROWSER_LOCATION_ACCESS_REQUIRED':
        msg = 'Please enable browser to access your location';
        break;
      case 'INVOICE_INFO_INCOMPLETE':
        msg = 'Invoice info incomplete';
        break;
      case 'FILES_REQUIRED':
        msg = 'Please upload some files';
        break;
      case 'COLUMNS_MISMATCH':
        msg = 'Mismatching Error';
        break;
      case 'EMPTY_EXCEL':
        msg = 'Empty Excel';
        break;
      case 'UNABLE_TO_SET_OLD_PASSWORD':
        msg = 'Please try another password, old passwords are not allowed';
        break;
      case 'WAIT_RESET_PASSWORD_EXPIRY_TIME':
        msg = 'You can`t reset password multiple time continuously, please wait for a while and try again.';
        break;
      case 'MOBILE_EXIST':
        msg = 'Mobile number exists, please try another one.';
        break;
      case 'INCOMPLETE_ADDRESS_FORM':
        msg = 'Please make sure you filled all required information.';
        break;
      case 'PASSWORD_CHANGED':
        msg = 'Your password is updated successfully.';
        break;
      case 'OLD_PASSWORD_WRONG':
        msg = 'The current password you entered is wrong.';
        break;
      case 'INVALID_PASSWORD':
        msg = 'Invalid password';
        break;
      case "MOBILE_NUMBER_CHANGED":
        msg = 'Your mobile number is changed successfully.';
        break;
      case "SECURITY_QUESTIONS_CHANGED":
        msg = 'Your security questions updated successfully.';
        break;
      case "INVALID_SECURITY_ANSWERS":
        msg = 'Invalid answers for your security questions';
        break;
      case "EMPTY_SELECTED_ROLE":
        msg = 'Please select a role';
        break;
      case "CHECK_EMAIL_INBOX":
        msg = 'Check your email inbox';
        break;
      case "PROFILE_UPDATED":
        msg = 'Your profile is updated successfully.';
        break;
      case "USER_NOT_EXIST":
        msg = 'Account does not exist';
        break;
      case "DEVICE_LOGGED_OUT":
        msg = 'Device logged out successfully.';
        break;
      case "CONTACT_REQUEST_NOT_FOUND":
        msg = 'Contact request not found or may be canceled.';
        break;
      case "CONTACT_REQUEST_CREATED":
        msg = 'Contact request created successfully.';
        break;
      case "NO_CONTACT_REQUEST_SENT":
        msg = 'Connection request cannot be sent, contact may disabled connect option.';
        break;
      case "AGREEMENT_CREATED":
        msg = "Agreement created successfully.";
        break;
      case "INVALID_TOTAL_AMOUNT_PAYMENTS":
        msg = 'Invalid Total Amounts and Payments';
        break;
      case "AGREEMENT_INFO_INCOMPLETE":
        msg = 'Agreement information is not completed';
        break;
      case 'AGREEMENT_DOCUMENTS_UPDATED':
        msg = 'Document updated';
        break;
      case 'AGREEMENT_NOTE_SENT':
        msg = 'Agreement Note is sent';
        break;
      case 'WAIT_VERIFICATION_CODE':
        msg = 'Verification code sent again, please wait..';
        break;
      case 'ONE_PRIMARY_EMAIL_ALLOWED':
        msg = 'Only one primary email address is allowed';
        break;
      case 'PAYMENTS_IMPORTED':
        msg = 'Payments imported successfully.';
        break;
      default:
        msg = MsgKey;
        break;
    }
    return msg;
  }
  static getCountryCodeFromMapDetails(data) {
    let PlaceData = {
      postal_code: {
        title: '',
        data: {}
      },
      country: {
        title: '',
        data: {}
      },
      administrative_area_level_1: {
        title: '',
        data: {}
      },
      administrative_area_level_2: {
        title: '',
        data: {}
      },
      locality: {
        title: '',
        data: {}
      },
      neighborhood: {
        title: '',
        data: {}
      },
      route: {
        title: '',
        data: {}
      },
      street_number: {
        title: '',
        data: {}
      },
      floor: {
        title: '',
        data: {}
      }
    }
    data.forEach((element) => {
      for (const key in PlaceData) {
        if (element.types.indexOf(key) !== -1) {
          PlaceData[key].title = element.short_name;
          PlaceData[key].data = element;
        }
      }
    });
    return PlaceData;
  }
  static GetUrlParamters(url) {
    let res = {};
    let paramString = url.split('?');
    if (paramString.length > 1) {
      let params_arr = paramString[1].split('&');
      for (let i = 0; i < params_arr.length; i++) {
        let pair = params_arr[i].split('=');
        res[pair[0]] = pair[1];
      }
    }
    return res;
  }
  static getSerialNumber() {
    return 'SRL123';
  }
  static getDevice() {
    //var header = [
    //navigator.platform,
    //navigator.userAgent,
    //navigator.appVersion,
    //navigator.vendor
    //];
    return navigator.platform;
  }
  static getIpAddress() {
    // this._http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
    //   return res.ip;
    // });
    let num1 = this.generateRandomInteger(10);
    let num2 = this.generateRandomInteger(10);
    let num3 = this.generateRandomInteger(10);
    let num4 = this.generateRandomInteger(10);
    let ip = num1 + '.' + num2 + '.' + num3 + '.' + num4;
    return ip;
  }
  static generateRandomInteger(max) {
    return Math.floor(Math.random() * max) + 1;
  }
  static getBrowser() {
    return navigator.vendor;
  }
  static getAgent() {
    return 'Test';
    // if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) return 'Opera';
    // else if(navigator.userAgent.indexOf("Chrome") != -1 ) return 'Chrome';
    // else if(navigator.userAgent.indexOf("Safari") != -1) return 'Safari';
    // else if(navigator.userAgent.indexOf("Firefox") != -1 ) return 'Firefox';
    // else if((navigator.userAgent.indexOf("MSIE") != -1 )) return 'IE'; 
    // else return 'unknown';
  }

  static formatPhone(v: any) {//v.location
    const results = phoneUtil.parseAndKeepRawInput(v.InternationalNumber, '');
    if (phoneUtil.isValidNumber(results)) {
      return {
        CountryCode: results.getCountryCode(),
        CountryLabel: phoneUtil.getRegionCodeForNumber(results),
        Number: results.getNationalNumber(),
        NumberFormat: phoneUtil.format(results, PNF.INTERNATIONAL)
      }
    } else {
      const code = phoneUtil.isPossibleNumberWithReason(results)
      switch (code) {
        case PNV.INVALID_COUNTRY_CODE:
          console.log('INVALID_COUNTRY_CODE');
          break;
        case PNV.TOO_SHORT:
          console.log('TOO_SHORT');
          break;
        case PNV.TOO_LONG:
          console.log('TOO_LONG');
          break;
        case PNV.IS_POSSIBLE_LOCAL_ONLY:
          console.log('IS_POSSIBLE_LOCAL_ONLY');
          break;
        case PNV.INVALID_LENGTH:
          console.log('INVALID_LENGTH');
          break;
        default:
          console.log('something is wrong');
      }
      return code;
    }
  }
  static AddMonths(d, c) {
    let OldD = new Date(d);
    let day = d.getDate();
    /////////////////////////////////////////////
    let nextDate = new Date(d.setMonth(d.getMonth() + c));
    let nextDay = nextDate.getDate();
    if (nextDay != day) {
      var firstDate = new Date(OldD.getFullYear(), OldD.getMonth(), 1);
      let virtualNextDate = new Date(firstDate.setMonth(firstDate.getMonth() + c));
      nextDate = new Date(virtualNextDate.getFullYear(), virtualNextDate.getMonth() + 1, 0);
    }
    return nextDate;
  }
  static yearDiff(dateFrom, dateTo) {
    var ageDifMs = dateTo - dateFrom;
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  static monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }

  static weeksBetween(d1, d2) {
    const week = 7 * 24 * 60 * 60 * 1000;
    let w = this.startOfWeek(d2);
    let y = this.startOfWeek(d1);
    let z = w - y;
    return Math.ceil(z / week);
  }
  static startOfWeek(dt) {
    const weekday = dt.getDay();
    const day = 24 * 60 * 60 * 1000;
    return new Date(dt.getTime() - Math.abs(0 - weekday) * day).getTime();
  }
  static roundNumber(num: number, scale?: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100
  }
  static roundNumberpay(num: number, scale?: number) {
    return Math.round((num) * 100) / 100
  }

  static ifDocExpired(doc) {
    if (!doc.ExpiryDate) return false;
    let date = doc.ExpiryDate;
    if (date == null) return false;
    let Today = new Date();
    let CompareDate = new Date(date);
    let res = (Today.getTime() > CompareDate.getTime()) ? true : false;
    return res;
  }
}
