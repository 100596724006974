<div class="left-layer">
  <app-logo [LogoType]="LogoColor"></app-logo>
</div>
<div class="right-layer">
  <div class="wizard-inquiry" *ngIf="logged">
          <a class="question-icon" [routerLink]="['/dashboard/help-section']"><i class="fas fa-question"></i></a>
        </div>
  <div id='ddcurrency' class="dropdown dpos login float-right" dropdown>
    <button class="float-right btn dropdown-toggle dropdown-currency ddl-rectangle" type="button"
      id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
      dropdownToggle>
      <span class="ddl-text">{{ selectedLanguage | uppercase  }}</span>
      <i class="fas fa-chevron-down"></i>
    </button>
    <div class="dropdown-menu" *dropdownMenu>
      <a class="dropdown-item dropdown-items-currency mt-0" (click)="onChangeLanguage('en')">{{"en" |translate}}</a>
      <a class="dropdown-item dropdown-items-currency mt-2" (click)="onChangeLanguage('ar')">{{"ar"|translate}}</a>
    </div>
  </div>
</div>