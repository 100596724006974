import { Injectable, EventEmitter } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  invokeSwitchAccountFunction = new EventEmitter();
  /////
  invokeCancelAgreementFunction = new EventEmitter();
  invokeActivateAgreementFunction = new EventEmitter();      
  invokeAcceptAgreementFunction = new EventEmitter();   
  invokeDeclineAgreementFunction = new EventEmitter();  
  // ///////
  ///////
  constructor() { } 
  callHeaderSwitchAccount(id) {  
    this.invokeSwitchAccountFunction.emit(id);    
  } 
  //////
  callAgreementCancel(id) {
    this.invokeCancelAgreementFunction.emit(id);  
  }
  callAgreementActivate(id) {
    this.invokeActivateAgreementFunction.emit(id);  
  }
  callAgreementDecline(id) {
    this.invokeDeclineAgreementFunction.emit(id);  
  }
  callAgreementApprove(id) {
    this.invokeAcceptAgreementFunction.emit(id);  
  }
  ///////
}