import { environment } from "./../../../../environments/environment";
import { StorageService } from "@core/services/storage.service";
import { Utils } from "./../../helper/utils";
import { Location } from "@angular/common";
import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "@core/services/shared.service";
import { ServiceLocator } from "../../services/locater.service";
import { Subscription } from "rxjs";
import { AlertifyService } from "@core/services/alertify.service";
import { CurrencyEnum } from "../../enumeration/CurrencyEnum";
import ar from "@angular/common/locales/ar";
import { registerLocaleData } from "@angular/common";
import { ActivatedRoute, Router, RouterEvent } from "@angular/router";
import { LookupEnums } from "@shared/enumeration/LookupEnum";
import { AbstractRestService } from "@shared/services/abstract.service";
import { ProfileSettingsService } from "@modules/dashboard/sections/profile-settings/profile-settings.service";
import { DateAdapter } from "@angular/material/core";
@Component({
  selector: "app-base",
  templateUrl: "./base.component.html",
  styleUrls: ["./base.component.css"],
})
export class BaseComponent implements OnInit, OnDestroy {
  ownerRoleId: number = 4;
  languages: any[] = [
    { symbol: 'en', title: 'En' },
    { symbol: 'ar', title: 'Ar' }
  ];
  public subscriptions: Subscription[] = [];
  selectedLanguage: string
  currentCountry: string = 'LB';
  static staticSelectedCurrency: string = LookupEnums.CurrencyEnum.USD.toString();
  static staticSelectedCurrencyText: string = "USD";
  picturePath: string =
    window.origin + "/api/matensa/profileimage/ImageNameForReplace";
  profileSettings: ProfileSettingsService;
  sharedService: SharedService;
  translate: TranslateService;
  alertify: AlertifyService;
  storageService: StorageService;
  router: Router;
  activatedRoute: ActivatedRoute;
  location: Location;
  isLogged: Boolean = true;
  linkPicture: string;
  pageTitle: string = "";

  fields: Object = { text: "Text", value: "Id" };
  selectTranslated: string;

  showLoading: boolean = true;
  Permissions: any[] = [];
  ReminderCategories: any[] = [];
  ReminderTypes: any[] = [];

  constructor(
    protected _abstractService?: AbstractRestService<any>,
  ) {
    this.translate = ServiceLocator.getInjector().get(TranslateService);
    this.sharedService = ServiceLocator.getInjector().get(SharedService);
    this.alertify = ServiceLocator.getInjector().get(AlertifyService);
    this.storageService = ServiceLocator.getInjector().get(StorageService);
    this.router = ServiceLocator.getInjector().get(Router);
    this.activatedRoute = ServiceLocator.getInjector().get(ActivatedRoute);
    this.location = ServiceLocator.getInjector().get(Location);
    this.profileSettings = ServiceLocator.getInjector().get(ProfileSettingsService);
    //this.authService = ServiceLocator.getInjector().get(AuthService);

    // this.sharedService.getRoleClaims()
    //   .then(permissions => {
    //     this.Permissions = permissions['Result']['Permissions'];
    //     console.log(this.Permissions['Tutorial'])
    //     // Perform any additional logic with the adminPermissions array
    //   });

    let currentSelectedLang: string = this.languages[0].symbol;
    if (
      typeof this.storageService.getLocalStorage("selected_language") !=
      "undefined" &&
      this.storageService.getLocalStorage("selected_language") != null
    ) {
      currentSelectedLang = this.storageService.getLocalStorage("selected_language");
      if (Utils.isNullOrUndefinedOrEmpty(currentSelectedLang)) {
        this.storageService.removeLocalStorage("selected_language");
        currentSelectedLang = this.languages[0].symbol;
        this.storageService.setLocalStorage("selected_language", currentSelectedLang);
      }
    }

    if (
      typeof this.storageService.getStorage("selected_currency") !=
      "undefined" &&
      this.storageService.getStorage("selected_currency") != null
    ) {
      BaseComponent.staticSelectedCurrency = this.storageService.getStorage(
        "selected_currency"
      );
    }

    if (
      !Utils.isNullOrUndefinedOrEmpty(
        this.storageService.getStorage("selected_currency_text")
      )
    ) {
      BaseComponent.staticSelectedCurrencyText = this.storageService.getStorage(
        "selected_currency_text"
      );
    } else {
      this.storageService.setStorage(
        "selected_currency",
        CurrencyEnum.USD.toString()
      );
    }
    // this.translate.setDefaultLang(currentSelectedLang);

    this.selectedLanguage = currentSelectedLang;
    this.useLanguage(currentSelectedLang);
    registerLocaleData(ar);

    this.subscriptions.push(
      this.sharedService._selectedLanguage$.subscribe((x) => {
        if (typeof x != "undefined" && x != null && x.length > 0) {
          this.useLanguage(x);
        }
      })
    );

    this.selectTranslated = this.translate.instant("select");
    // this.loading$?.subscribe((x) => {
    //   debugger;
    // });
    this.subscriptions.push(
      this.sharedService._countryCode$.subscribe((x) => {
        if (typeof x != "undefined" && x != null && x.length > 0) {
          this.currentCountry = x;
        }
        else {
          this.sharedService.set_countryCode();
        }
      })
    );



  }
  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void { }

  ngOnInit() {

    if (!Utils.isNullOrUndefinedOrEmpty(this._abstractService))
      this.subscriptions.push(
        this._abstractService.loading$.subscribe((x) => {
          // setTimeout(() => {
          this.showLoading = x;
          //}, 2000);
        })
      );
    // this.requestPermission();
    // this.listen();

  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  get isRegisteredByEmail() {
    return this.sharedService.getLoggedInUser().IsRegisteredByEmail;
  }
  get _FCMClientToken() {
    return this.storageService.getStorage('FCMToken');
  }
  get isPersonlAccount() {
    let getLoggedInUser = this.LoggedInUser;
    if (Utils.isNullOrUndefinedOrEmpty(getLoggedInUser)) {
      this.storageService.clearStorage();
      // this.router.navigate(['/auth/login'])
      this.isLogged = false;
    }
    else {
      return (
        this.storageService.getStorage('IsPersonal') != LookupEnums.UserTypeEnum.Business
      );
    }
  }
  get IsBusiness() {
    return (this.storageService.getStorage('IsPersonal') == LookupEnums.UserTypeEnum.Business) ? true : false;
  }
  get LoggedInUser() {
    return this.sharedService.getLoggedInUser();
  }
  get LoggedInUserId() {
    let decodedToken = this.storageService.getDecodedToken();
    if (decodedToken)
      return decodedToken.AccountId;
    //return (!Utils.isNullOrUndefinedOrEmpty(this.LoggedInUser)) ? this.LoggedInUser['AccountId'] : '';
  }
  get selectedCurrency() {
    return BaseComponent.staticSelectedCurrency;
  }

  get selectedCurrencyText() {
    return BaseComponent.staticSelectedCurrencyText;
  }
  //    inactivityTime () {
  //     console.warn("start")
  //     var time;
  //     window.onload = resetTimer;
  //     // DOM Events
  //     document.onmousemove = resetTimer;
  //     document.onkeypress = resetTimer;

  //     function resetTimer() {
  //         clearTimeout(time);
  //         time = setTimeout(this.logout, 3000)
  //     }
  // };



  useLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.storageService.setLocalStorage('lang', language);
    //this.sharedService.setSelectedLanguage(language);
    if (language == "ar")
      document.querySelector("body").classList.add("rtl");
    else document.querySelector("body").classList.remove("rtl");
    this.selectedLanguage = language;
    this.translate.use(language);

  }

  getLinkPicture(AccountId: string = null) {
    try {
      this.linkPicture =
        environment.apiUrl +
        "User/GetProfileImage/" +
        (AccountId != null
          ? AccountId
          : this.sharedService.getLoggedInUser().AccountId) +
        "?" +
        new Date().getTime();
      return this.linkPicture;
    } catch (ex) { }
  }

  getLoggedInUserName(checkCompanyName: boolean = true) {
    //return this.storageService.getCurrentUserName("logged_in_user");
    // if (!Utils.isNullOrUndefinedOrEmpty(this.sharedService.getLoggedInUser())) {
    //   const loggedInUser = this.sharedService.getLoggedInUser();
    //   if (checkCompanyName)
    //     return loggedInUser.useCompanyName
    //       ? loggedInUser.CompanyName
    //       : loggedInUser.firstName;
    //   return loggedInUser.firstName;
    // }
    return "";
  }

  viewReason(reminder) {
    this.alertify.alertMessage(reminder.ReasonOfDecline, "decline_reason");
  }

  formattingNumber(x: string) {
    //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    return Number(x).toLocaleString("en-GB");
  }

  homeClicked(hstate: boolean) {
    //this.isWelcomePage = hstate;
    window.scrollTo(0, 0);
    console.log("homeClicked = " + hstate);
  }

  aboutUsClicked(rstate: boolean) {
    //this.isAboutUsPage = rstate;
    window.scrollTo(0, 0);
    // if (this.isAboutUsPage && this.isWelcomePage) {
    //   this.isWelcomePage = false;
    // }
    console.log("aboutUsClicked = " + rstate);
    //console.log('isWelcomePage = ' + this.isWelcomePage);
  }

  getInTouchClicked(rstate: boolean) {
    //this.isGetInTouchPage = rstate;
    window.scrollTo(0, 0);
    // if (this.isGetInTouchPage && this.isWelcomePage) {
    //   this.isWelcomePage = false;
    // }
    console.log("getInTouchClicked = " + rstate);
    //console.log('isWelcomePage = ' + this.isWelcomePage);
  }

  termsConditionsClicked(rstate: boolean) {
    //this.isTermsConditions = rstate;
    window.scrollTo(0, 0);
    // if (this.isTermsConditions && this.isWelcomePage) {
    //   this.isWelcomePage = false;
    // }
    console.log("termsConditionsClicked = " + rstate);
    //console.log('isWelcomePage = ' + this.isWelcomePage);
  }

  privacyPolicyClicked(rstate: boolean) {
    // this.isPrivacyPolicy = rstate;
    window.scrollTo(0, 0);
    // if(this.isPrivacyPolicy && this.isWelcomePage) {
    //   this.isWelcomePage = false;
    // }
    console.log("privacyPolicyClicked= " + rstate);
    //console.log('isWelcomePage = ' + this.isWelcomePage);
  }

  qaClicked(rstate: boolean) {
    //this.isQA = rstate;
    window.scrollTo(0, 0);
    // if(this.isQA && this.isWelcomePage) {
    //   this.isWelcomePage = false;
    // }
    console.log("QAClicked= " + rstate);
    //console.log('isWelcomePage = ' + this.isWelcomePage);
  }

  registerClicked(rstate: boolean) {
    //this.isRegisterPage = rstate;
    //window.scrollTo(0,0);
    console.log("registerClicked = " + rstate);
  }

  navigateByUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  navigateTo(
    activatedRoute: ActivatedRoute,
    routerPath: string = "",
    id: string = "",
    agreementSetupId: string = ""
  ) {
    if (
      !Utils.isNullOrUndefinedOrEmpty(activatedRoute.queryParams) &&
      !Utils.isNullOrUndefinedOrEmpty(activatedRoute.snapshot.queryParams)
    ) {
      if (Utils.isNullOrUndefinedOrEmpty(routerPath))
        this.navigateByUrl(
          "dashboard/(dashboardOutler:contactdetails)?id=" +
          (id == "" ? activatedRoute.snapshot.queryParams.id : id) +
          "&agreementSetupId=" +
          (agreementSetupId == ""
            ? activatedRoute.snapshot.queryParams.agreementSetupId
            : agreementSetupId)
        );
      else
        this.navigateByUrl(
          "dashboard/(dashboardOutler:contactdetails/(cdsidebar:" +
          routerPath +
          "))?id=" +
          (id == "" ? activatedRoute.snapshot.queryParams.id : id) +
          "&agreementSetupId=" +
          (agreementSetupId == ""
            ? activatedRoute.snapshot.queryParams.agreementSetupId
            : agreementSetupId)
        );
    }
  }

  displayErrorMessage(err) {
    const error = err.error || err.statusText || err.message || err;
  }

  displaySuccessMessage(_object) {
    this.alertify.success("success");
  }

  // onChangeLanguage(language) {
  //   this.sharedService.setSelectedLanguage(language);
  //   this.selectedLanguage = language;
  // }

  subscribeLoading(services: AbstractRestService<any>[]) {
    if (services && services.length > 0)
      services.forEach((service) => {
        this.subscriptions.push(
          service.getLoading().subscribe((x) => {
            this.showLoading = x;
          })
        );
      });
  }
  ////////////////////////////////////////
  setLoginData(userObj) {
    if (!Utils.isNullOrUndefinedOrEmpty(userObj)) {
      const currentTimePlusHalfHour = new Date(
        new Date().setMinutes(
          new Date().getMinutes() + parseInt(environment.sessionLife)
        )
      ).getTime();
      this.storageService.setSessionStorage(
        "valid_token",
        currentTimePlusHalfHour.toString()
      );
      this.storageService.setStorage('IsPersonal', (userObj.AccountTypeLKPId) ? userObj.AccountTypeLKPId : userObj.TypeLKPId);
      this.storageService.setLocalStorage("isFromLogin", true);
      this.sharedService.setLoggedInUser(userObj);
    }
  }
  get userRole() {
    let decToken = this.storageService.getDecodedToken();
    if (!Utils.isNullOrUndefinedOrEmpty(decToken)) {
      return decToken.RoleId;
    }
    else return false;
  }
  get userPermissions() {
    let RoleId = this.userRole;
    if (RoleId == this.ownerRoleId) {
      return [];
    }
    else {
      let AllPermissions = this.profileSettings.SystemPermissions;
      let obj = AllPermissions.find(x => x.Id == RoleId);
      if (obj) return obj['Permissions'];
    }
  }

  has_permission(section, action) {
    // let bool = false;
    let bool = true;
    // return true;
    let Permissions = localStorage.getItem("Permissions");
    let arr = [];

    if (!Utils.isNullOrUndefinedOrEmpty(Permissions)) {
      arr = JSON.parse(Permissions);
      if (!Utils.isNullOrUndefinedOrEmpty(arr[section])) {
        bool = arr[section][action];

      }
    }
    return bool;
  }

  checkPermission(Perm) {
    return true;
    let RoleId = this.userRole;
    if (RoleId == this.ownerRoleId) {
      return true;
    }
    else {
      let AllPermissions = this.profileSettings.SystemPermissions;
      if (!Utils.isNullOrUndefinedOrEmpty(AllPermissions)) {
        let Obj = AllPermissions.find(x => x.Id == RoleId);
        if (!Utils.isNullOrUndefinedOrEmpty(Obj)) {
          if (Obj['Permissions'].indexOf(Perm) !== -1) {
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
  }
  ////////////////////////////////////////
  ifUtils(x) {
    if (Utils.isNullOrUndefinedOrEmpty(x)) {
      return true;
    }
    else {
      return false;
    }
  }
  ////////////////////////////////////////
  GoToConnectionProfileid(id) {
    this.router.navigate(['/dashboard/connection-profile/' + id])
  }
  ////////////////////////////////////
  // token:string = '';
  // requestPermission() {
  //   const messaging = getMessaging();
  //   // let IfToken = this.storageService.getStorage('FirebaseToken');
  //   // if(!Utils.isNullOrUndefinedOrEmpty(IfToken)) {
  //   //   this.token = IfToken;
  //   //   this.subscribeTokenToTopic(IfToken,'global');
  //   //   if(!Utils.isNullOrUndefinedOrEmpty(this.LoggedInUserId))
  //   //     this.subscribeTokenToTopic(IfToken,this.LoggedInUserId);
  //   // }
  //   // else {
  //     getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey }).then((currentToken) => {
  //       if (currentToken) {
  //         this.token = currentToken;
  //         this.storageService.setStorage('FCMToken',this.token);
  //         //console.warn('token: '+this.token)
  //         this.subscribeTokenToTopic(currentToken,'global');
  //         if(!Utils.isNullOrUndefinedOrEmpty(this.LoggedInUserId))
  //           this.subscribeTokenToTopic(currentToken,this.LoggedInUserId);
  //         // Send the token to your server and update the UI if necessary
  //         // ...
  //       } else {
  //         // Show permission request UI
  //         console.warn('No registration token available. Request permission to generate one.');
  //         // ...
  //       }
  //     }).catch((err) => {
  //       console.warn('An error occurred while retrieving token. ', err);
  //       // ...
  //     });
  //     // deleteToken(messaging).then(x=>{

  //     // })
  //  // }
  // }
  // subscribeTokenToTopic(token, topic) {
  //   let checkIfGlobalIsSubScribed = this.storageService.getStorage('FirebaseSubscribed-'+topic);
  //   if(Utils.isNullOrUndefinedOrEmpty(checkIfGlobalIsSubScribed) || checkIfGlobalIsSubScribed != token) {
  //     fetch('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, {
  //       method: 'POST',
  //       headers: new Headers({
  //       Authorization: 'key=AAAAwvg3bbg:APA91bEbKgWEik8QIwZHOS8Iu9blrYpEd2tfCFAKQ8htCtUkwVX5EGq-mUGatgvaZ9h6gq1FNEfksGhbTIbksStqCtrzTCuCSLbgc3EYMkM3A9lXYxAQQvF-A3S_epw1rxwKKYaS7K7U'
  //       })
  //     }).then((response) => {
  //       //  if (response.status < 200 || response.status >= 400) {
  //       //     console.warn(response.status, response);
  //       //  }
  //       this.storageService.setStorage('FirebaseSubscribed-'+topic,token);
  //       console.warn(topic+' is subscribed on '+token);
  //     })
  //     .catch((error) => {
  //     console.warn(error.result);
  //     });
  //   }
  //   else {
  //     console.warn(topic+' was already subscribed on '+token);
  //   }
  //   return true;
  // }
}
